<template>
  <div id="first">
    <IntroPage />
  </div>
  <div>
    <Projects />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import IntroPage from '../components/IntroPage.vue';
import Projects from '../components/Projects_showcasing.vue'

export default defineComponent({
  name: 'HomeView',

  components: {
    IntroPage, Projects
  },
});
</script>

<style>


@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@400;500;600;700&display=swap');

#first{
  height: 100vh;
  padding-bottom: 50px;
  font-family: 'Kantumruy Pro', sans-serif !important; 
}

*, html, body{
  font-family: 'Kantumruy Pro', sans-serif !important; 
}

</style>
