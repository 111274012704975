<template>
  <div id="cont">
    <div id="heading-main">Our Lovable Projects</div>
    <div id="r1">
      <img class="pr_img" :src="projBorguna" />
      <div id="pr_info_1">
        <div id="heading">Four storied residential & commercial building</div>
        <div id="_txt" class="pb-4">
          Client: Amol Debnath & Brothers
          <br />
          Location: Barguna, Barishal
          <br />
          Service: Structure Engineering
          <br />
          Area: 5000 sqft
        </div>
        <a href="https://www.facebook.com/titasconsultants" target="_blank"
          ><v-btn color="grey-darken-3" size="large">CONTACT US</v-btn>
        </a>
      </div>
    </div>

    <div id="r2">
      <div id="pr_info_2">
        <div id="heading">Ten storied residential building</div>
        <div id="_txt" class="pb-4">
          Client: Eighteen Tower
          <br />
          Location: Jubilee road, Chittagong
          <br />
          Service: Site supervision
          <br />
          Area: 5000 sqft
        </div>
        <a
          href="https://www.facebook.com/photo?fbid=134134569747827&set=a.128962520265032"
          target="_blank"
          ><v-btn color="grey-darken-3" size="large">CHECK PROJECT</v-btn>
        </a>
      </div>
      <img class="pr_img" :src="projEighteen" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projEighteen: require("../assets/proj_eighteen.png"),
      projBorguna: require("../assets/proj_borguna.jpg"),
    };
  },
};
</script>

<style>
#r1 {
  padding-bottom: 75px;
}

#r2 {
  display: flex;
}

#r1,
#r2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: auto;
}

* {
  overflow-y: visible;
  overflow-x: hidden;
}

.pr_img {
  width: auto;
  height: 30rem;
}

#cont {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 10rem;
}

#pr_info_1 {
  margin-left: 50px;
  height: auto !important;
  overflow: hidden;
}

#pr_info_2 {
  margin-right: 50px;
  height: auto !important;
  overflow: hidden;
}

#heading {
  font-size: 35px;
  font-weight: 500;
  padding-bottom: 7px;
}

#heading-main {
  text-align: center;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 5rem;
}

#_txt {
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 800px) {
  #r1,
  #r2 {
    flex-direction: column;
  }
  .pr_img {
    object-fit: cover;
    height: 220px !important;
    width: 100%;
  }
  #pr_info_1,
  #pr_info_2 {
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 20px;
  }
  #pr_info_2 {
    order: 1;
  }
}

@media screen and (min-width: 1300px) {
  #heading {
    font-size: 40px;
  }
  #_txt {
    font-size: 19px;
  }
}
</style>
