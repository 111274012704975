<template>
  <v-container fluid fill-height>
    <video autoplay muted loop :src="videoSrc" class="fullscreen-video"></video>

    <div class="overlay">
      <v-col>
        <v-row>
          <v-col class="text-center">
            <div class="welcome pb-1">WELCOME TO TITAS CONSULTANTS</div>
            <v-row class="sh" justify="center">
              <div class="opening pt-1">
                We are here to shape your dream home
              </div>
              <!--<div class="exc pt-2 "> !! </div>-->
            </v-row>
          </v-col>
        </v-row>
        <v-col>
          <v-col class="services">
            <v-row justify="center"
              ><div>
                <span style="font-weight: 700">Services Based On</span>
              </div></v-row
            >
            <v-row justify="center"
              ><div>
                Architectural Design | Structural Design | Electrical Design |
                Plumbing Design | Project Management | Estimation | Site
                Supervision
              </div></v-row
            >
          </v-col>
          <v-row justify="center"> </v-row>
        </v-col>
        <div class="buttons mt-3 pt-2">
          <v-btn
            id="soln"
            elevation="24"
            size="large"
            rounded="lg"
            target="_blank"
            :href="asksolutionlink"
          >
            Ask for a solution
          </v-btn>
          <v-btn
            id="design"
            elevation="24"
            size="large"
            rounded="lg"
            :href="dream_design"
            target="_blank"
          >
            FREE CONSULTANCY
          </v-btn>
        </div>
        <v-row justify="center" class="footer">
          <a href="https://www.facebook.com/titasconsultants/" target="_blank">
            <v-icon size="28" class="mr-2">mdi-facebook</v-icon>
          </a>
          <a
            href="https://www.linkedin.com/company/titas-consultants/"
            target="_blank"
          >
            <v-icon size="28" class="mr-2">mdi-linkedin</v-icon>
          </a>
          <div class="vl mt-1 mr-2"></div>
          <div class="footer-text mt-1">Titas Consultants © 2023</div>
        </v-row>
      </v-col>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      videoSrc: require("../assets/intro-video.mp4"),
      asksolutionlink:
        "https://api.whatsapp.com/send?phone=%2B8801836477941&data=ARBskS_Qx0JiUFe-oGZVgYA3CNhBr79jARi5Ol9Sspldd4oOAUy3duwt0iQlCrcvxqeSf8l94HMEiHZz0r1wLQuH_O2YneH9LR9h8nHVOG-18ZvlNregInx3YMHTZDgp1p2Nuv5QqkpDzZrGKmUo_Ao&source=tcons_web&app=facebook&entry_point=page_cta",
      dream_design: "https://forms.gle/2zAawtXuUamSudVa6",
    };
  },
  mounted() {
    window.setTimeout(() => {
      document.getElementsByClassName("sh")[0].style.animation =
        "transitionIn3 2.0s forwards";
      document.getElementsByClassName("buttons")[0].style.animation =
        "transitionIn2 2.0s forwards";
      document.getElementsByClassName("footer")[0].style.animation =
        "transitionIn4 3.0s forwards";
    }, 1000);
  },
};
</script>

<style src="./IntroPage.css"></style>
